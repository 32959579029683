import { defineStore } from "pinia";
import http from "./http.js";

export const useJurisdictionStore = defineStore("jurisdiction", {
  state: () => ({
    requestStatus: "",
    error: "",
    jurisdictions: [],
    jurisdiction: null,
    users: [],
    audiences: [],
    totalElements: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: 10,
    affairTypes: [],
  }),

  getters: {
    getRequestStatus(state) {
      return state.requestStatus;
    },
    getItems(state) {
      return state.jurisdictions;
    },
    getItem(state) {
      return state.jurisdiction;
    },
    getUsers(state) {
      return state.users;
    },
    getAudiences(state) {
      return state.audiences;
    },
    getTotalElements(state) {
      return state.totalElements;
    },
    getTotalPages(state) {
      return state.totalPages;
    },
    getCurrentPage(state) {
      return state.currentPage;
    },
    getPageSize(state) {
      return state.pageSize;
    },
    getAffairTypes(state) {
      return state.affairTypes;
    },
  },

  actions: {
    async index(page = 0, size = 1000, jurisdictionType="JURISDICTION", searchQuery = "") {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get(`jurisdictions?page=${page}&size=${size}&search=${searchQuery}&jurisdictionType=${jurisdictionType}`);
        const { data, currentPage, totalPages, totalElements, pageSize } = response.data;
        this.jurisdictions = data;
        this.totalElements = totalElements;
        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.error = error;
        this.requestStatus = "FAILED";
      }
    },
    
    async store(data) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.post("jurisdictions", data);
        this.jurisdiction = response.data;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.error = error;
        this.requestStatus = "FAILED";
      }
    },

    async show(id) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get("jurisdictions/" + id);
        this.jurisdiction = response.data;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.error = error;
        this.requestStatus = "FAILED";
      }
    },

    async update(data) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.put("jurisdictions", data);
        this.jurisdiction = response.data;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.error = error;
        this.requestStatus = "FAILED";
      }
    },

    async delete(id) {
      this.requestStatus = "LOADING";
      try {
        await http.delete("jurisdictions/" + id);
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.error = error;
        this.requestStatus = "FAILED";
      }
    },

    async fetchUsers(id) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get("jurisdictions/" + id + "/users");
        this.users = response.data;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.error = error;
        this.requestStatus = "FAILED";
      }
    },

    async fetchAudiences(id) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get("jurisdictions/" + id + "/audiences");
        this.audiences = response.data;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.error = error;
        this.requestStatus = "FAILED";
      }
    },

    async fetchAffairTypes(id) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get("jurisdictions/" + id + "/affairTypes");
        this.affairTypes = response.data;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.error = error;
        this.requestStatus = "FAILED";
      }
    },
  },
});
