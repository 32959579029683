<template>
  <el-menu
    default-active="2"
    class="container pb-5"
    background-color="#fff"
    text-color="#000"
    active-text-color="dodgerblue"
    :router="true"
    :collapse="isCollapsed"
    style="position: fixed; height: 100%; overflow: auto; min-height: 700px; max-width:250px; z-index:0; padding-top:47px; min-width:80px!important;"
  >
    <el-menu-item :disabled="!userApi.getChain.length || userApi.getChamberId == null"
      index="5"
      class="d-flex flex-row menu-item"
      :route="{ name: 'dashboard' }"
      v-if="userApi.getIsAdmin == false"
    >
      <vs-icon
        class="material-icons-outlined blue-light-text-color"
        icon="home"
        size="20px"
      ></vs-icon>
      <span class="menu-text">{{ $t("home") }}</span>
    </el-menu-item>

    <el-menu-item :disabled="!userApi.getChain.length || userApi.getChamberId == null"
      index="1"
      class="d-flex flex-row menu-item"
      :route="{ name: 'investigation_processes' }"
    >
      <vs-icon
        class="material-icons-outlined blue-light-text-color"
        icon="folder"
        size="20px"
      ></vs-icon>
      <span class="menu-text" style="padding-top:10px!important;">{{ $t("investigation_processes") }}</span>
      <el-badge type="warning" :value="processCount" class="mx-1" style=""  >
      </el-badge>
    </el-menu-item>

    <el-menu-item v-if="positionApi.hasAccess('ACCESS_AUDIENCE')" :disabled="!userApi.getChain.length || userApi.getChamberId == null" index="2" class="d-flex flex-row" :route="{ name: 'audiences' }" style="margin-top:15px!important;">
      <vs-icon
        class="material-icons-outlined blue-light-text-color"
        icon="calendar_month"
        size="20px" style="margin-left:-4px!important;"
      ></vs-icon>
      <span class="menu-text">{{ $t("audiences") }}</span>
    </el-menu-item>

    <el-menu-item  v-if="positionApi.hasAccess('ACCESS_PERSONS')" :disabled="!userApi.getChain.length || userApi.getChamberId == null" index="3" class="d-flex flex-row" :route="{ name: 'persons' }">
      <vs-icon
        class="material-icons-outlined blue-light-text-color"
        icon="groups"
        size="20px"
      ></vs-icon>
      <span class="menu-text">{{ $t("persons") }}</span>
    </el-menu-item>

    <el-menu-item v-if="positionApi.hasAccess('ACCESS_SPEAKERS')" :disabled="!userApi.getChain.length || userApi.getChamberId == null" index="4" class="d-flex flex-row" :route="{ name: 'speakers' }">
      <vs-icon
        class="material-icons-outlined blue-light-text-color"
        icon="interpreter_mode"
        size="20px"
      ></vs-icon>
      <span class="menu-text">{{ $t("speakers") }}</span>
    </el-menu-item>

    <el-menu-item  v-if="positionApi.hasAccess('ACCESS_STATISTICS') && userApi.getIsAdmin == false" :disabled="!userApi.getChain.length || userApi.getChamberId == null"
      index="9"
      class="d-flex flex-row"
      :route="{ name: 'statistics' }"
    >
      <vs-icon
        class="material-icons-outlined blue-light-text-color"
        icon="analytics"
        size="20px"
      ></vs-icon>
      <span class="menu-text">{{ $t("statistics") }}</span>
    </el-menu-item>

    <el-menu-item :disabled="!userApi.getChain.length || userApi.getChamberId == null" index="5" class="d-flex flex-row" :route="{ name: 'public-audiences' }">
      <vs-icon
        class="material-icons-outlined blue-light-text-color"
        icon="article"
        size="20px"
      ></vs-icon>
      <span class="menu-text">{{ $t("audience_roles") }}</span>
    </el-menu-item>

    <el-menu-item :disabled="!userApi.getChain.length || userApi.getChamberId == null"
      index="6"
      class="d-flex flex-row"
      :route="{ name: 'chain_selection' }"
      v-if="userApi.getChain.length"
    >
      <vs-icon
        class="material-icons-outlined blue-light-text-color"
        icon="balance"
        size="20px"
      ></vs-icon>
      <span class="menu-text">{{ $t(userApi.getChain + "_CHAIN") }}</span>
    </el-menu-item>

    <el-submenu index="7" v-if="userApi.getIsAdmin == true" style="margin-bottom:300px!important;">
      <template slot="title">
        <vs-icon
          class="material-icons-outlined blue-light-text-color"
          icon="settings"
          size="20px"
          style="color: darkviolet"
        ></vs-icon>
        <span class="menu-text">{{ $t("configuration") }}</span>
      </template>

      <!-- lcalities -->
      <el-menu-item-group :title="$t('localities')">
        <el-menu-item  index="7-1" class="d-flex flex-row" :route="{ name: 'localities' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="location_city"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("localities") }}</span>
        </el-menu-item>

        <el-menu-item  index="7-2" class="d-flex flex-row" :route="{ name: 'nations' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="language"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("countries") }}</span>
        </el-menu-item>
      </el-menu-item-group>
      <!-- end localities -->




      <!-- jurisdictions -->
      <el-menu-item-group :title="$t('jurisdictions')">
        <el-menu-item 
          index="7-3"
          class="d-flex flex-row"
          :route="{ name: 'jurisdiction_types', params:{type:'JURISDICTION'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="fork_left"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("jurisdiction_types") }}</span>
        </el-menu-item>

        <el-menu-item 
          index="7-4"
          class="d-flex flex-row"
          :route="{ name: 'jurisdictions', params:{type:'JURISDICTION'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="gavel"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("jurisdictions") }}</span>
        </el-menu-item>




        <!-- les unité d investigation -->
        <el-menu-item 
          index="7-3"
          class="d-flex flex-row"
          :route="{ name: 'jurisdiction_types', params:{type:'INVESTIGATION_UNIT'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="fork_left"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("investigation_unit_types") }}</span>
        </el-menu-item>

        <el-menu-item 
          index="7-20"
          class="d-flex flex-row"
          :route="{ name: 'jurisdictions', params:{type:'INVESTIGATION_UNIT'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="gavel"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("investigationUnits") }}</span>
        </el-menu-item>





        <!-- les bars -->

        <el-menu-item 
          index="7-21"
          class="d-flex flex-row"
          :route="{ name: 'jurisdiction_types', params:{type:'BAR'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="fork_left"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("bar_types") }}</span>
        </el-menu-item>


        <el-menu-item 
          index="7-21"
          class="d-flex flex-row"
          :route="{ name: 'jurisdictions', params:{type:'BAR'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="gavel"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("bars") }}</span>
        </el-menu-item>




        <!-- les chambres nationales -->

        <el-menu-item 
          index="7-22"
          class="d-flex flex-row"
          :route="{ name: 'jurisdiction_types', params:{type:'NATIONAL_CHAMBER_OF_BAILIFFS'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="fork_left"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("national_chamber_of_bailiffs_types") }}</span>
        </el-menu-item>

        <el-menu-item 
          index="7-22"
          class="d-flex flex-row"
          :route="{ name: 'jurisdictions', params:{type:'NATIONAL_CHAMBER_OF_BAILIFFS'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="gavel"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("NationalChamberOfBailiffs") }}</span>
        </el-menu-item>


        <!-- les cps -->
         <el-menu-item 
          index="7-23"
          class="d-flex flex-row"
          :route="{ name: 'jurisdiction_types', params:{type:'CPS'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="fork_left"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("cps_types") }}</span>
        </el-menu-item>  

        <el-menu-item 
          index="7-24"
          class="d-flex flex-row"
          :route="{ name: 'jurisdictions', params:{type:'CPS'} }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="gavel"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("cps") }}</span>
        </el-menu-item>


        <el-menu-item  index="7-5" class="d-flex flex-row" :route="{ name: 'chambers' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="work"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("chambers") }}</span>
        </el-menu-item>
      </el-menu-item-group>
      <!-- end jurisdictions -->





      <!-- users -->
      <el-menu-item-group :title="$t('users')">
        <el-menu-item  index="7-6" class="d-flex flex-row" :route="{ name: 'positions' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="assignment_ind"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("positions") }}</span>
        </el-menu-item>

        <el-menu-item  index="7-7" class="d-flex flex-row" :route="{ name: 'users' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="badge"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("users") }}</span>
        </el-menu-item>

        <el-menu-item  index="7-8" class="d-flex flex-row" :route="{ name: 'admins' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="contact_emergency"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("administrators") }}</span>
        </el-menu-item>
      </el-menu-item-group>
      <!-- end users -->

      <!-- investigations -->
      <el-menu-item-group :title="$t('investigation_processes')">
        <el-menu-item 
          index="7-"
          class="d-flex flex-row"
          :route="{ name: 'application_status' }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="question_mark"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("application_status") }}</span>
        </el-menu-item>

        <el-menu-item 
          index="7-10"
          class="d-flex flex-row"
          :route="{ name: 'person_types' }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="local_offer"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("person_types") }}</span>
        </el-menu-item>


        <el-menu-item  index="7-11" class="d-flex flex-row" :route="{ name: 'affair_types' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="cases"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("affair_types") }}</span>
        </el-menu-item>

          <el-menu-item  index="7-11" class="d-flex flex-row" :route="{ name: 'affairs' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="cases"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("affairs") }}</span>
        </el-menu-item>
        <el-menu-item  index="7-12" class="d-flex flex-row" :route="{ name: 'referrals' }">
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="assignment_ind"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("referrals") }}</span>
        </el-menu-item>

        <el-menu-item 
          index="7-13"
          class="d-flex flex-row"
          :route="{ name: 'person_status_in_investigations' }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="info"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("person_status_in_investigations") }}</span>
        </el-menu-item>

        <el-menu-item 
          index="7-14"
          class="d-flex flex-row"
          :route="{ name: 'person_situation_in_investigations' }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="fact_check"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("person_situation_in_investigations") }}</span>
        </el-menu-item>
        <el-menu-item 
          index="7-15"
          class="d-flex flex-row"
          :route="{ name: 'investigation_events' }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="notifications_active"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("investigation_events") }}</span>
        </el-menu-item>
      </el-menu-item-group>
      <!-- end investigations -->

      <!-- audiences -->
      <el-menu-item-group :title="$t('audiences')">
        <el-menu-item 
          index="7-16"
          class="d-flex flex-row"
          :route="{ name: 'audience_types' }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="local_offer"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("audience_types") }}</span>
        </el-menu-item>

        <el-menu-item 
          index="7-17"
          class="d-flex flex-row"
          :route="{ name: 'audience_results' }"
        >
          <vs-icon
            class="material-icons-outlined text-muted"
            icon="build"
            size="20px"
          ></vs-icon>
          <span class="menu-text">{{ $t("audience_results") }}</span>
        </el-menu-item>
      </el-menu-item-group>
      <!-- end audiences -->

      <!-- action -->
      <el-menu-item 
        index="8"
        class="d-flex flex-row"
        :route="{ name: 'trames' }"
      >
        <vs-icon
          class="material-icons-outlined text-muted"
          icon="construction"
          size="20px"
        ></vs-icon>
        <span class="menu-text">{{ $t("trames") }}</span>
      </el-menu-item>
      <!-- action -->

      <el-menu-item 
        index="14"
        class="d-flex flex-row"
        :route="{ name: 'document-models' }"
      >
        <vs-icon
          class="material-icons-outlined text-muted"
          icon="description"
          size="20px"
        ></vs-icon>
        <span class="menu-text">{{ $t("documentModels") }}</span>
      </el-menu-item>


      <!-- attachment_types -->
      <el-menu-item 
        index="13"
        class="d-flex flex-row"
        :route="{ name: 'attachment_types' }"
      >
        <vs-icon
          class="material-icons-outlined text-muted"
          icon="content_copy"
          size="20px"
        ></vs-icon>
        <span class="menu-text">{{ $t("attachment_types") }}</span>
      </el-menu-item>
      <!-- attachment_types -->


      <!-- bundles -->
      <el-menu-item  index="15" class="d-flex flex-row" :route="{ name: 'bundles' }">
        <vs-icon
          class="material-icons-outlined text-muted"
          icon="content_copy"
          size="20px"
        ></vs-icon>
        <span class="menu-text">{{ $t("bundles") }}</span>
      </el-menu-item>
      <!-- bundles -->

      <!-- repport_types -->
      <el-menu-item  index="11" class="d-flex flex-row" :route="{ name: 'repport_types' }">
        <vs-icon
          class="material-icons-outlined text-muted"
          icon="content_copy"
          size="20px"
        ></vs-icon>
        <span class="menu-text">{{ $t("repport_types") }}</span>
      </el-menu-item>
      <!-- repport_types -->

      <!-- action -->
      <el-menu-item  index="12" class="d-flex flex-row" :route="{ name: 'settings' }">
        <vs-icon
          class="material-icons-outlined text-muted"
          icon="tune"
          size="20px"
        ></vs-icon>
        <span class="menu-text">{{ $t("settings") }}</span>
      </el-menu-item>
      <!-- action -->
    </el-submenu>
  </el-menu>
</template>
<script>
import { useUserStore } from "@/store/UserService";
import { usePositionStore } from '@/store/PositionService';
export default {
  props: {
    isCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userApi: useUserStore(),
      processCount: 0,
      positionApi: usePositionStore()
    };
  },

  created() {
    this.getProcesses();
  },

  methods: {
    getProcesses() {
      this.$vs.loading();
      this.userApi.fetchInvestigationProcesses(this.userApi.getUserId).then(() => {
        this.processCount = this.userApi.getInvestigationProcesses.filter((item) => {
          return item.validated == false;
        }).length;
        this.$vs.loading.close();
      });
    },
  },
};
</script>
<style scoped>
.el-menu :hover {
  background: none !important;
}
.el-menu-item-group__title {
  font-size: 14px !important;
  color: blue !important;
  font-weight: bolder !important;
}
.menu-item {
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 16px !important;
}

.menu-text {
  font-size: 14px !important;
  font-weight: normal !important;
  margin-left: 10px !important;
  margin-top: -18px !important;
}

:deep(.el-submenu__title) {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 14px !important;
}

:deep(.el-menu-item-group__title) {
  font-size: 12px !important;
  padding: 6px 16px !important;
}

/* Ajustement pour les badges */
:deep(.el-badge__content) {
  transform: scale(0.8);
  transform-origin: top right;
}
</style>
